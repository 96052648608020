import { BrowserRouter, Routes, Route, useNavigate, useLocation } from "react-router-dom";
import UsersListing from "./pages/Users/Listing";
import ChangePassword from "./pages/ChangePassword";
import Home from "./pages/Home";
import ProfileView from "./pages/Profile/View";
import SetPassword from "./pages/SetPassword";
import Settings from "./pages/Settings";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Thankyou from "./pages/Thankyou";
import OrganisationsListing from "./pages/organisations/index";
import SearchDate from "./organisms/Search/SearchDate";
import SearchNumber from "./organisms/Search/SearchNumber";
import InvoiceList from "./pages/Invoice/Processed";
import InvoiceView from "./pages/Invoice/View";
import ResetPassword from "./pages/ResetPassword";
import InvoiceTable from "./organisms/Invoice/Table";
import EmailConfig from "./pages/EmailConfig";
import IntegrationServer from "./pages/IntegrationServer";
import ExceptionList from "./pages/Invoice/Exception";
import FileUpload from "./pages/FileUpload";
import ForgotPassUI from "./pages/ForgotPassword";
import ThankyouSignup from "./pages/Thankyou/signupcomplete";
import FournotFour from "./pages/404";
import OAuthLogin from "./pages/OAuthLogin";
import OAuthError from "./pages/OAuthError";
import ExpiryLink from "./pages/ExpiryLink";
import RoleMaster from "./pages/Users/RoleMaster";
import NewRole from "./pages/Users/RoleMaster/NewRole/NewRole";
import AdminSetting from "./pages/Settings/AdminSetting";
import InvoiceLayout from "./pages/Invoice/Layout";
import EditRole from "./pages/Users/RoleMaster/EditRole";
import { useSelector } from "react-redux";
import IdConfig from "./pages/Invoice/IdConfig";
import Reports from "./pages/Reports";
import ReportsHome from "./pages/Reports/Home";
import AccrualAccounting from "./pages/Acrual/Accounting";
import InvoiceRule from "./pages/Rules";
import RuleBased from "./pages/Rules/RuleBased";
import ReportsAgeing from "./pages/ReportsAgeing";
import InvoiceAllocation from "./pages/Invoice/Allocation";
import AccrualSecondary from "./pages/Acrual/secondary";
import AccrualPost from "./pages/Acrual/Post";
import ViewRuleItems from "./pages/Rules/ViewRule";
import AccrualReport from "./pages/Reports/Accrual";
import ReportsTask from "./pages/ReportsTask/index";
import { Notification } from "./pages/Notification";
import InboxConfiguration from "./pages/Inbox/InboxConfiguration";
import InboxConfigConfirmation from "./pages/Inbox/InboxConfiguration/InboxConfigConfirmation";
import InboxSettings from "./pages/Inbox/InboxConfiguration/InboxSettings";
import ListTable from "./pages/MyTask/MyTaskList";
import { InpoxCompose } from "./pages/Inbox/Compose";
import { EmailTemplate } from "./pages/Inbox/EmailTemplate";
import { TemplateCreate } from "./pages/Inbox/EmailTemplate/TemplateCreate";
import PriorityConfiguration from "./pages/MyTask/CreateTask/PriorityConfiguration";
import TaskIdConfiguration from "./pages/MyTask/CreateTask/TaskIdConfiguration";
import UserPage from "./pages/NoOrganisationAllocated";
import { getCurrentUser } from "./redux/actions/auth";
import BillingReport from "./pages/Reports/Billing";
import { CookieDisable } from "./pages/CookieDisable";
import { useEffect, useState } from "react";
import { areCookiesEnabled, getCookie } from "./utils/helpers";
import MonthEndClosing from "./pages/Acrual/MonthEnd";
import { MaintananceWindow } from "./pages/MaintananceWindow";
import { ScheduledOutput } from "./pages/Reports/ScheduledReports";
import ReportSummaryList from "./pages/Reports/OutputManagement/ReportSummary";
import CustomReports from "./pages/Reports/OutputManagement/CustomReports";
import WorkflowAutomation from "./pages/WorkflowAutomation";
import ExchangeRate from "./pages/ExchangeRate/ExchangeRate";
import MobilizationRequest from "./pages/AdavnceRequest/MobilizationRequest/index";
// import ExceptionAutomation from "./pages/ExceptionAutomation";
import AdvanceListItem from "./pages/AdavnceRequest/AdvanceList/index";
import MiscellaneousPaymentRequest from "./pages/MiscellaneousPaymentRequest";
import MiscellaneousPaymentList from "./pages/MiscellaneousPaymentRequest/MiscellaneousPaymentTable";
import RetentionRelease from "./pages/RetentionRelease/index";
import { DocumentConfig } from "./pages/DocumentConfig";
import ExceptionAutomation from "./pages/ExceptionAutomation";
import RetentionReleaseTable from "./pages/RetentionRelease/ReationReleaseTable";
import RetentionReports from "./pages/Reports/RetentionReportList";
import MiscellaneousReports from "./pages/Reports/MiscellaneousReports";
import AdvanceReports from "./pages/Reports/AdvanceReport";
import AccountSynchronization from "./pages/AccountSynchronization/index";
import VendorOnboarding from "./pages/VendorOnboarding";
import { Preview } from "./pages/VendorOnboarding/Preview";

export const AllRoutes = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const DisplayRole = useSelector((state) => state?.DisplayRolePermissionStateAction);
    const { data: DataList } = DisplayRole;
    const RoleList = DataList?.role_info;
    const RoleMenu = RoleList?.menu;
    const Roleorg = RoleMenu?.find((elem) => elem?.menu_id === 1);
    const RoleActOrg = Roleorg?.actions;
    const checkorg = RoleActOrg?.some((element) => element.isallowed === true);
    const RoleUser = RoleMenu?.find((elem) => elem?.menu_id === 6);
    const RoleActUser = RoleUser?.actions;
    const checkUser = RoleActUser?.some((element) => element.isallowed === true);
    const RoleProcess = RoleMenu?.find((elem) => elem?.menu_id === 8);
    const RoleActProcess = RoleProcess?.actions;
    const checkProcess = RoleActProcess?.some((element) => element.isallowed === true);
    const RoleException = RoleMenu?.find((elem) => elem?.menu_id === 9);
    const RoleActException = RoleException?.actions;
    const checkException = RoleActException?.some((element) => element.isallowed === true);
    const RoleDashboard = RoleMenu?.find((elem) => elem?.menu_id === 11);
    const RoleActdashboard = RoleDashboard?.actions;
    const checkdashboard = RoleActdashboard?.find((element) => element.id === 19);
    const RoleUpload = RoleMenu?.find((elem) => elem?.menu_id === 12);
    const RoleActUpload = RoleUpload?.actions;
    const checkUpload = RoleActUpload?.find((element) => element.id === 21);

    const RoleAccrual = RoleMenu?.find((elem) => elem?.menu_id === 17);
    const RoleAccrualAct = RoleAccrual?.actions;
    const checkAccrual = RoleAccrualAct?.find((element) => element.id === 3);
    const RoleMonthEnd = RoleMenu?.find((elem) => elem?.menu_id === 19);
    const RoleActMonthEnd = RoleMonthEnd?.actions;
    const checkMonthEnd = RoleActMonthEnd?.find((element) => element.id === 3);
    const RoleExchangerate = RoleMenu?.find((elem) => elem?.menu_id === 27);
    const RoleActExchangeRate = RoleExchangerate?.actions;
    const checkExchangeRate = RoleActExchangeRate?.find((element) => element.id === 22);
    const RoleAdvance = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleActAdvance = RoleAdvance?.actions;
    const checkAdvance = RoleActAdvance?.some((element) => element.isallowed === true);
    const date = new Date();
    const d = date.toLocaleDateString("en-GB");
    const currentUser = getCurrentUser();

    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate && currentUser?.license_type == 2;
    const [cookiesEnabled, setCookiesEnabled] = useState(areCookiesEnabled());
    // const JWT = getCookie("jwt");
    const RoleAccrualReports = RoleMenu?.find((elem) => elem?.menu_id === 13);
    const CheckAcrrulReports = RoleAccrualReports?.actions?.[0]?.isallowed;

    const RoleInvoiceReports = RoleMenu?.find((elem) => elem?.menu_id === 14);
    const CheckInvoiceReports = RoleInvoiceReports?.actions?.[0]?.isallowed;

    const RoleReportsAgeing = RoleMenu?.find((elem) => elem?.menu_id === 15);
    const CheckReportsAgeing = RoleReportsAgeing?.actions?.[0]?.isallowed;

    const RoleTaskReports = RoleMenu?.find((elem) => elem?.menu_id === 18);
    const CheckTaskReports = RoleTaskReports?.actions?.[0]?.isallowed;
    const ShowProffesionIcon = (!matchdate && currentUser?.license_type == 2) || currentUser?.license_type !== 4;

    const RoleAdv = RoleMenu?.find((elem) => elem?.menu_id === 21);
    const RoleActAdvCreate = RoleAdv?.actions?.filter((el) => el?.id == 1)?.[0]?.isallowed;
    const RoleActAdvEdit = RoleAdv?.actions?.filter((el) => el?.id == 2)?.[0]?.isallowed;
    const RoleActAdvView = RoleAdv?.actions?.filter((el) => el?.id == 3)?.[0]?.isallowed;

    const RoleMisc = RoleMenu?.find((elem) => elem?.menu_id === 22);
    const RoleActMiscCreate = RoleMisc?.actions?.filter((el) => el?.id == 1)?.[0]?.isallowed;
    const RoleActMiscEdit = RoleMisc?.actions?.filter((el) => el?.id == 2)?.[0]?.isallowed;
    const RoleActMiscView = RoleMisc?.actions?.filter((el) => el?.id == 3)?.[0]?.isallowed;

    function getChromeVersion() {
        const userAgent = navigator.userAgent;
        const match = userAgent.match(/Chrom(?:e|ium)\/([0-9.]+)/);

        if (match) {
            return match[1];
        } else {
            return "Not Chrome";
        }
    }

    // Usage example
    const chromeVersion = getChromeVersion();
    useEffect(() => {
        let animationFrameId;

        const updateCookiesEnabled = () => {
            setCookiesEnabled(areCookiesEnabled());
            animationFrameId = requestAnimationFrame(updateCookiesEnabled);
        };

        updateCookiesEnabled(); // Start the animation loop

        return () => {
            cancelAnimationFrame(animationFrameId); // Cancel the animation loop on unmount
        };
    }, []);

    useEffect(() => {
        if (!cookiesEnabled && location.pathname !== "/cookie-disabled") {
            navigate("/cookie-disabled");
        } else if (cookiesEnabled && location.pathname === "/cookie-disabled") {
            navigate(-1);
        }
    }, [cookiesEnabled, location.pathname]);
    const params = new URLSearchParams(window.location.search);
    const reqId = params.get("reqId") || 0;
    const Run404 =
        (RoleActAdvEdit && RoleActAdvView ? !RoleActAdvEdit && reqId && !RoleActAdvView : false) ||
        (RoleActAdvCreate && reqId ? !RoleActAdvCreate && location.pathname == "/mobilization-request" && !reqId : false);
    const Run404Misc =
        (!RoleActMiscEdit && reqId && !RoleActMiscView) || (!RoleActMiscCreate && location.pathname == "/miscellaneous-request" && !reqId);

    return (
        <>
            <Routes>
                {/* <Route path="/" element={<MaintananceWindow />} /> */}
                {/* <Route path="/" element={<MaintananceWindow />} /> */}
                {/* <Route path="*" element={<MaintananceWindow />} /> */}
                <Route path="/" element={<SignIn />}>
                    <Route element={<SignIn />} />
                </Route>
                <Route path="/404" element={<FournotFour />} />
                <Route path="/dashboard" element={checkdashboard?.isallowed == false ? <FournotFour /> : <Home />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/oAuth-login" element={<OAuthLogin />} />
                <Route path="/oAuth-error" element={<OAuthError />} />
                <Route path="/cookie-disabled" element={<CookieDisable />} />
                <Route path="/users" element={checkUser == false ? <FournotFour /> : <UsersListing />} />
                <Route path="/organisations" element={checkorg == false ? <FournotFour /> : <OrganisationsListing />} />
                <Route path="/profile" element={<ProfileView />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/searchdate" element={<SearchDate />} />
                <Route path="/searchnum" element={<SearchNumber />} />
                <Route path="/invoice" element={checkProcess == false ? <FournotFour /> : <InvoiceList />} />
                <Route path="/processed" element={checkProcess == false ? <FournotFour /> : <InvoiceList />} />
                <Route path="/invoice-view" element={<InvoiceView />} />
                <Route path="/proforma-invoice-view" element={<InvoiceView />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/invoice-table" element={<InvoiceTable />} />
                <Route path="/configure" element={<EmailConfig />} />
                <Route path="/exception" element={checkProcess == false ? <FournotFour /> : <InvoiceList />} />
                <Route path="/approve" element={<InvoiceList />} />
                <Route path="/server-config" element={<IntegrationServer />} />
                <Route path="/file-upload" element={checkUpload?.isallowed == false ? <FournotFour /> : <FileUpload />} />
                <Route path="/forgotpassword" element={<ForgotPassUI />} />
                <Route path="/thankyousignup" element={<ThankyouSignup />} />
                <Route path="/expirylink" element={<ExpiryLink />} />
                <Route path="/role-master" element={matchdate ? <FournotFour /> : <RoleMaster />} />
                <Route path="/new-role" element={<NewRole />} />
                <Route path="/edit-role" element={<EditRole />} />
                <Route path="/admin-setting" element={currentUser?.role_id == 1 && !checkUser && !checkorg ? <FournotFour /> : <AdminSetting />} />
                <Route path="/invoice-layout" element={matchdate ? <FournotFour /> : <InvoiceLayout />} />
                <Route path="/invoiceid-config" element={matchdate ? <FournotFour /> : <IdConfig />} />
                <Route path="/reports/reports-detail" element={!CheckInvoiceReports ? <FournotFour /> : <Reports />} />
                <Route path="/reports/reports-ageing" element={!CheckReportsAgeing ? <FournotFour /> : <ReportsAgeing />} />
                <Route path="/reports" element={<ReportsHome />} />
                <Route
                    path="/reports/reports-task"
                    element={!CheckTaskReports || currentUser?.license_type == 4 ? <FournotFour /> : <ReportsTask />}
                />
                <Route
                    path="/reports/reports-accrual"
                    element={!CheckAcrrulReports || currentUser?.license_type == 4 ? <FournotFour /> : <AccrualReport />}
                />
                <Route
                    path="/accrual-accounting"
                    element={matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 ? <FournotFour /> : <AccrualAccounting />}
                />
                <Route
                    path="/accrual-secondary"
                    element={checkAccrual?.isallowed == false || matchdate || currentUser?.license_type == 4 ? <FournotFour /> : <AccrualSecondary />}
                />
                <Route
                    path="/month-closing"
                    element={checkMonthEnd?.isallowed == false || matchdate || currentUser?.license_type == 4 ? <FournotFour /> : <MonthEndClosing />}
                />
                <Route
                    path="/invoice-rule"
                    element={
                        matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 || currentUser?.license_type == 3 ? (
                            <FournotFour />
                        ) : (
                            <InvoiceRule />
                        )
                    }
                />
                <Route
                    path="/invoice-rulebased"
                    element={
                        matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 || currentUser?.license_type == 3 ? (
                            <FournotFour />
                        ) : (
                            <RuleBased />
                        )
                    }
                />
                <Route
                    path="/invoice-allocation"
                    element={matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 ? <FournotFour /> : <InvoiceAllocation />}
                />
                <Route path="/accrual-post" element={matchdate || currentUser?.license_type == 4 ? <FournotFour /> : <AccrualPost />} />
                <Route path="/ViewRule" element={<ViewRuleItems />} />
                <Route path="/notification" element={<Notification />} />
                <Route path="/inbox-config" element={currentUser?.license_type == 4 ? <FournotFour /> : <InboxConfiguration />} />
                <Route path="/inbox-config/google" element={currentUser?.license_type == 4 ? <FournotFour /> : <InboxConfigConfirmation />} />
                <Route path="/inbox-config/outlook" element={currentUser?.license_type == 4 ? <FournotFour /> : <InboxConfigConfirmation />} />
                <Route path="/inbox-settings" element={currentUser?.license_type == 4 ? <FournotFour /> : <InboxSettings />} />
                <Route path="/inbox-compose" element={currentUser?.license_type == 4 ? <FournotFour /> : <InpoxCompose />} />
                <Route path="/my-task/list" element={currentUser?.license_type == 4 ? <FournotFour /> : <ListTable />} />
                <Route
                    path="/emailtemplate"
                    element={matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 ? <FournotFour /> : <EmailTemplate />}
                />
                <Route
                    path="/emailtemplate/view"
                    element={matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 ? <FournotFour /> : <TemplateCreate />}
                />
                <Route
                    path="/priority-config"
                    element={matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 ? <FournotFour /> : <PriorityConfiguration />}
                />
                <Route
                    path="/taskid-config"
                    element={matchdate || currentUser?.license_type == 4 || currentUser?.role_id == 1 ? <FournotFour /> : <TaskIdConfiguration />}
                />
                <Route path="/user-page" element={<UserPage />} />
                <Route path="/reports/reports-billing" element={<BillingReport />} />
                <Route path="/reports/scheduled" element={<ScheduledOutput />} />
                <Route path="/reports/reports-summary-list" element={<ReportSummaryList />} />
                <Route path="/reports/custom-reports-list" element={<CustomReports />} />
                <Route path="/reports/custom-reports-list-matrix" element={<CustomReports />} />
                <Route path="/workflow-automation" element={<WorkflowAutomation />} />
                <Route path="/exchange-rate" element={!checkExchangeRate?.isallowed ? <FournotFour /> : <ExchangeRate />} />
                <Route path="/mobilization-request" element={Run404 ? <FournotFour /> : <MobilizationRequest />} />
                <Route path="/adhoc-advance-request" element={<MobilizationRequest />} />
                <Route path="/advance-list" element={checkAdvance && !checkAdvance ? <FournotFour /> : <AdvanceListItem />} />
                <Route path="/retention-release" element={<RetentionRelease />} />
                <Route path="/retention-release-list" element={<RetentionReleaseTable />} />
                <Route path="/miscellaneous-request" element={Run404Misc && !Run404Misc ? <FournotFour /> : <MiscellaneousPaymentRequest />} />
                <Route path="/miscellaneous-table" element={RoleActMiscView && !RoleActMiscView ? <FournotFour /> : <MiscellaneousPaymentList />} />
                <Route path="/retention-release-list" element={<RetentionReleaseTable />} />
                {/* <Route path="/exception-automation" element={<ExceptionAutomation />} /> */}
                <Route path="/retention-release" element={<RetentionRelease />} />
                <Route path="/retention-release-list" element={<RetentionReleaseTable />} />

                <Route path="/document-type" element={<DocumentConfig />} />
                <Route path="/exception-automation" element={<ExceptionAutomation />} />

                <Route path="/reports/retention-reports" element={<RetentionReports />} />
                <Route path="/reports/miscellaneous-reports" element={<MiscellaneousReports />} />
                <Route path="/reports/advance-reports" element={<AdvanceReports />} />
                <Route path="*" element={<FournotFour />} />
                <Route path="/account-sync" element={<AccountSynchronization />} />
                <Route path="/vendor-onboard" element={<VendorOnboarding />} />
                <Route path="/vendor-onboard/preview" element={<Preview />} />
            </Routes>
        </>
    );
};
