import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Rnd } from "react-rnd";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { getCurrentUser } from "../../redux/actions/auth";
import { ChatbotRequest, sendMessage } from "../../redux/actions/chatbot";

export const ChatBot = () => {
    const { currentMode } = useContext(ThemeContext);
    const [show, setShow] = useState({ normal: false, chatbot: false }); // show hide chatbot icon and popup combined state!
    const messagesEndRef = useRef(null); // Create a ref for the last message!
    // Calculate the initial x and y coordinates to position the element at the bottom-right corner
    const viewportWidth = window.innerWidth; // Get the width of the viewport
    const viewportHeight = window.innerHeight; // Get the height of the viewport
    const elementWidth = 100; // Set the desired width of the element
    const elementHeight = 100; // Set the desired height of the element
    const elementWidthchat = 400; // Set the desired width of the element
    const elementHeightchat = 657;
    const initialX = viewportWidth - elementWidth; // Calculate the initial x-coordinate
    const initialY = viewportHeight - elementHeight; // Calculate the initial y-coordinate
    const initialChatX = viewportWidth - elementWidthchat;
    const initialChatY = viewportHeight - elementHeightchat;
    const [initialchatX, setInitialchatX] = useState(initialX);
    const [initialchatY, setInitialchatY] = useState(initialY);

    const [value, setValue] = useState(""); //textbox state after onchange stored!
    const [bgColor, setBgColor] = useState(false);
    const dispatch = useDispatch();
    // Textbox onchange callback!
    const _handleChange = (e) => {
        setValue(e.target.value);
    };
    let currentUser = getCurrentUser();
    const currentTime = moment();
    // Format and display the current time
    const formattedTime = currentTime.format("hh:mm A");
    const userData = {
        country_id: currentUser?.country_id,
        country_code: currentUser?.country_code,
        user_name: currentUser?.username,
        timezone: currentUser?.timezone,
    };
    // Normal button click callback!
    const _handleSub = () => {
        const reqObj = {
            sender: "" + currentUser?.id,
            message: value,
            user: userData,
        };
        dispatch(sendMessage(value, formattedTime));
        dispatch(ChatbotRequest(reqObj, formattedTime));
        setValue("");
    };
    // Enter Key Press callback!
    const handleKeyPress = (e) => {
        if (e.key === "Enter" && value !== "") {
            _handleSub();
        }
    };
    // get data from reducer state!
    const { fetching, chatMessages } = useSelector((state) => state.chatbotState);
    // Function to scroll to the last message
    const scrollToBottom = () => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        // Scroll to the last message whenever messages change
        scrollToBottom();
    }, [chatMessages]);
    // BotMessage seperated because multiple map loop had response data array
    const BotMessage = ({ data }) => {
        return (
            <>
                {data?.response?.map((el, i) => {
                    return (
                        <React.Fragment key={i}>
                            <div className={`chatbot_bot_msg max-w-[85%] w-max bg-light-1100 py-[16px] px-[10px]`}>
                                <p>{el?.text}</p>
                            </div>
                        </React.Fragment>
                    );
                })}
                {/* Conditionally render the loading indicator */}
                <small className={`text-xs mb-2 block text-${currentMode}-1080`}>{data?.time}</small>
                <div ref={messagesEndRef} />
            </>
        );
    };

    const handleResize = (e, direction, ref, delta, position) => {
        // Check the new width to decide the background color
        const newWidth = parseInt(ref.style.width, 10);

        // Set the background color based on the new width
        if (500 > newWidth) {
            setBgColor(false);
        } else {
            setBgColor(true);
        }
    };

    const handleDragStop = (e, d) => {
        // Calculate maximum x position based on viewport width and element width
        const maxX = window.innerWidth - elementWidth;
        const maxY = window.innerHeight - elementHeight;
        const newX = Math.min(maxX, Math.max(0, d.x));
        const newY = Math.min(maxY, Math.max(0, d.y));

        // Update the x position
        setInitialchatX(newX);
        setInitialchatY(newY); // You might want to update the y position as well
    };

    return (
        <>
            {!show?.normal && (
                <Rnd
                    default={{
                        x: initialchatX,
                        y: initialchatY,
                        width: elementWidth,
                        height: elementHeight,
                    }}
                    cancel=".cancel-drag"
                    // dragHandleClassName="handle"
                    bounds="window"
                    dragAxis="both" // Allow dragging in both horizontal and vertical directions
                    onDragStop={handleDragStop}
                    className="z-[11] fixed">
                    <div className="fixed cancel-drag bottom-[30px] z-[10] right-[30px]" title="You can drag the button">
                        {!show?.chatbot && (
                            <>
                                {" "}
                                <img
                                    src="/images/chatbot/chatbot-logo.svg"
                                    className="cursor-pointer"
                                    onClick={() =>
                                        setShow({
                                            normal: false,
                                            chatbot: true,
                                        })
                                    }
                                    alt="chatbot-logo"
                                />
                                {/* <span className="absolute top-0 right-[-15px]">
                                    <img
                                        className="cursor-pointer"
                                        onClick={() =>
                                            setShow({
                                                normal: true,
                                                chatbot: false,
                                            })
                                        }
                                        src="/images/chatbot/cls-sml.svg"
                                        alt="cls-sml"
                                    />
                                </span> */}
                            </>
                        )}
                    </div>
                </Rnd>
            )}
            <Rnd
                style={{
                    zIndex: show?.chatbot ? 12 : 1,
                }}
                className={`chatbot_main bottom-0 fixed ${show?.chatbot ? "showing" : ``}`}
                onResize={handleResize}
                default={{
                    x: initialChatX, // Position at the right end
                    y: initialChatY, // Position at the bottom end
                    width: `400px`,
                    height: `657px`,
                }}
                disableDragging // Set to true if you want to disable dragging
            >
                <div
                    className={`chatbot_box ${
                        show?.chatbot ? "showing" : ``
                    } bg-${currentMode}-card border border-${currentMode}-1090 shadow-[0px_4px_20px_0px] shadow-${currentMode}-750 rounded-[12px]`}>
                    <div className="">
                        <div className={`chatbot_header justify-between text-white flex rounded-t-[10px] ${bgColor ? `bg-${currentMode}-20` : ``}`}>
                            <div className="flex space-x-3">
                                <div>
                                    <img src="/images/chatbot/white-logo.svg" alt="white-logo" />
                                </div>
                                <div>
                                    <h5 className={`font-interSb text-[20px] text-white`}>iQ Bot</h5>
                                    <p>Here to help you with iQ Bot</p>
                                </div>
                            </div>
                            <span className="cursor-pointer" onClick={() => setShow(false)}>
                                <img src="/images/chatbot/x.svg" alt="x" />
                            </span>
                        </div>
                    </div>
                    <div className={`chatbot_body h-[30rem] overflow-y-auto p-[20px] border-t-0`}>
                        <span className={`text-center text-xs mb-2 block text-${currentMode}-1080`}>Today, {formattedTime}</span>
                        <div className="bot_msg">
                            <span className="mb-2 flex space-x-2">
                                <img src="/images/chatbot/small-logo.svg" alt="small-logo" />
                                <h5 className={`text-${currentMode}-copy`}>iQ Bot</h5>
                            </span>
                            <div className={`chatbot_bot_msg max-w-[85%] w-max bg-light-1100 py-[16px] px-[10px]`}>
                                <span>Hi! I’m a iQ Bot. Nice to meet you!</span>
                            </div>
                            <div className={`chatbot_bot_msg max-w-[85%] w-max bg-light-1100 py-[16px] px-[10px]`}>
                                <span>What information are you looking for?</span>
                            </div>
                        </div>
                        {chatMessages?.length !== 0 &&
                            chatMessages?.map((message, i) => {
                                const isLastMessage = i === chatMessages.length - 1;

                                return (
                                    <React.Fragment key={i}>
                                        {message?.isUser ? (
                                            <>
                                                <div className="user_msg">
                                                    <span className={`text-end block mb-1 text-${currentMode}-copy`}>You</span>
                                                    <div
                                                        className={`chatbot_user_msg max-w-[85%] w-max ml-auto bg-light-20 text-white py-[16px] px-[10px]`}>
                                                        <span>{message?.text?.message}</span>
                                                    </div>
                                                    <small className={`text-end text-xs mb-2 block text-${currentMode}-1080`}>
                                                        {message?.text?.time}
                                                    </small>
                                                </div>
                                                {isLastMessage && fetching && (
                                                    <div className="dotAnimation">
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="bot_msg">
                                                <span className="mb-2 flex space-x-2">
                                                    <img className="" src="/images/chatbot/small-logo.svg" alt="small-logo" />
                                                    <h5 className={`text-${currentMode}-copy`}>iQ Bot</h5>
                                                </span>
                                                <BotMessage data={message?.text} />
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}

                        {/* <div
                        className={`chatbot_bot_msg bg-${currentMode}-1100 py-[16px] px-[10px]`}
                    >
                        <p className="text-center">
                            Share your Ratings & Feedback
                        </p>
                        <div className="flex justify-center items-center space-x-3 py-[15px]">
                            <img
                                className="cursor-pointer"
                                onClick={() => setReview(true)}
                                src="/images/chatbot/happy.svg"
                                alt="happy"
                            />
                            <img
                                className="cursor-pointer"
                                onClick={() => setReview(true)}
                                src="/images/chatbot/neutral.svg"
                                alt="neutral"
                            />
                            <img
                                className="cursor-pointer"
                                onClick={() => setReview(true)}
                                src="/images/chatbot/sad.svg"
                                alt="sad"
                            />
                        </div>
                        {review && (
                            <textarea
                                placeholder="Please enter your feedback"
                                className="resize-none placeholder:text-[#737376] h-[90px] text-xs font-interR flex-auto p-[10px] rounded-[5px] w-full"
                                name=""
                            />
                        )}
                    </div> */}
                    </div>
                    <div className={`chatbot_footer border-${currentMode}-1090 border-t items-center flex justify-between py-4 px-[20px]`}>
                        <Input
                            placeholder="Type Message here..."
                            className={`placeholder:text-[#737376] bg-transparent text-xs font-interR text-${currentMode}-copy flex-auto p-0 h-[25px]`}
                            name="chatbot"
                            value={value}
                            onChange={_handleChange}
                            onKeyPress={handleKeyPress}
                        />
                        <Button className="flex-inherit" disabled={value == ""} onClick={_handleSub}>
                            <img src="/images/chatbot/sent-btn.svg" alt="sent-btn" />
                        </Button>
                    </div>
                </div>
            </Rnd>
        </>
    );
};
