import Input from "../../components/Input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { loginRequest, tokenAuthRequest } from "../../redux/actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LOGIN_RESET } from "../../redux/types/types";
import MessageBox from "../../components/UI/Alert";
import Button from "../../components/Button";
import { useFormik } from "formik";
import { LoginValidateSchema } from "../../Hooks/validateschema";
import { Tooltip } from "recharts";
import { getOrganisation, listOrganisationDropDown } from "../../redux/actions/organisation";
import { decryptId, getOS, getSystemInfo } from "../../utils/helpers";
import axios from "axios";
import { encryptId } from "../../utils/helpers";
import { fetchToken } from "../../components/Firebase";
import { fnBrowserDetect } from "../../utils/helpers";
import { RolePermissionDisplay } from "../../redux/actions/role";
import { stringify } from "@firebase/util";
import localforage from "localforage";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

// const firebaseConfig = {
//   apiKey: "AIzaSyDsQ4PxiGjnMnmBveOR9yHjkyBeiEjpLdk",
//   authDomain: "test-myiqai.firebaseapp.com",
//   projectId: "test-myiqai",
//   storageBucket: "test-myiqai.appspot.com",
//   messagingSenderId: "399253264787",
//   appId: "1:399253264787:web:6455c15bcb2066be52b066"
// };
const errorMsg = "Business Email or password is incorrect";
// const getSystemInfo = async () => {
//   const result = await axios.get("http://ip-api.com/json").then((response) => {
//     if (response.status == 200) {
//       return response?.data;
//     } else {
//       return "";
//     }
//   });
//   return result;
// };

const Form = () => {
    const [password, SetPassword] = useState("");
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isSubmit, setSubmit] = useState(false);
    // Date object
    var date = new Date();
    const offset = date.getTimezoneOffset();
    const offsets = stringify(offset);
    const result = offsets?.replace("-", "+");

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let secret = localStorage.getItem("secretId");
    const formik = useFormik({
        initialValues: {
            login_email: "",
            login_password: "",
        },

        validationSchema: LoginValidateSchema,
        onSubmit: async (values) => {
            let uniqueId = uuidv4();
            if (secret === null) {
                localStorage.setItem("secretId", encryptId(uniqueId));
            }
            let fcmToken = await fetchToken();
            let systemInfo = new Object();
            systemInfo.info = null; //await getSystemInfo()
            systemInfo.browser = fnBrowserDetect();
            systemInfo.os = await getOS();
            systemInfo.secretid = secret === null ? uniqueId : decryptId(secret);
            setSubmit(true);
            dispatch(
                loginRequest({
                    emailaddress: encryptId(values?.login_email.toLowerCase()),
                    password: encryptId(values?.login_password),
                    fcm_token: fcmToken,
                    logininfo: JSON.stringify(systemInfo),
                    logintype: 1,
                    timezone: result,
                    //   encryptId()
                })
            );
        },
    });

    const { data, fetching, error, requestComplete } = useSelector((state) => state?.loginState);

    const handleOnChangeText = (e) => {
        setSubmit(false);
        formik.handleChange(e);
    };
    const responseGoogle = (response) => {};
    const handleResponse = (data) => {
        // alert(data);
    };

    // async function getToken(tokenRequest) {
    //     const cca = new msal.ConfidentialClientApplication(config);
    //     const msalTokenCache = cca.getTokenCache();
    //  return await cca.acquireTokenByClientCredential(tokenRequest);
    // }

    const microsoftlogin = async () => {};

    const ButtonContent = () => {
        return (
            <span>
                <button
                    type="button"
                    className="flex w-[100%] flex-1 font-interR justify-center  shadow-[0px_0px_1px_0px_rgb(0,0,0,0.24),0px_2px_2px_0px_rgb(0,0,0,0.24)] text-light-40 rounded-[4px] bg-white p-[10px_10px_10px_10px]">
                    <img className="mr-4" src="/images/signin/outlook.svg" alt="outlook" /> Login with Microsoft
                </button>
            </span>
        );
    };

    const loginGoogle = () => {
        window.location.href = `${process.env?.REACT_APP_NODE_API_URL}auth/google`;
    };
    const loginOutlook = () => {
        window.location.href = `${process.env?.REACT_APP_NODE_API_URL}auth/outlook`;
    };

    const getMenuId = localStorage.getItem("menuId");
    const getOrgId = localStorage.getItem("orgId");
    //const getUserId = localStorage.getItem("user");
    const getDefaultLogin = localStorage.getItem("defaultLogin");
    const getFolder = JSON.parse(localStorage.getItem("FolderAccess"));
    const userToken = Cookies.get("jwt");
    const removeItemFromLocalForage = async (id) => {
        try {
            await localforage.removeItem(id);
        } catch (error) {}
    };
    useEffect(() => {
        if (userToken && getMenuId && getDefaultLogin) {
            navigate(getDefaultLogin);
        } else if (getMenuId === "0" && getOrgId === "0") {
            navigate("/user-page");
        }
        const menuaItems = process.env?.REACT_APP_MENU_LIST;
        const menuList = JSON.parse(menuaItems);
        // const menuName = menuList.find(item=>item.id==getMenuId);
        const menuName = menuList.find((item) => item.id == data?.allowed_menu);
        // if (getMenuId && getOrgId) {
        //   dispatch(listOrganisationDropDown());
        //   dispatch(RolePermissionDisplay({ orgid: getOrgId }));
        //   // dispatch(tokenAuthRequest(userToken?.token))
        // }
        return () => {
            dispatch({ type: LOGIN_RESET });
        };
    }, [requestComplete, dispatch, navigate]);
    useEffect(() => {
        if (userToken) {
            dispatch(tokenAuthRequest(userToken));
        }
    }, []);
    const LoginDateSplit = data?.login_date?.split("T")[0];
    if (requestComplete) {
        localStorage.setItem("orgId", data?.default_orgid);
        localStorage.setItem("LoginDate", LoginDateSplit);
        localStorage.setItem("orgGroup", data?.organisation);
        sessionStorage.setItem("orgId", data?.default_orgid);
        localStorage.setItem("menuId", data?.allowed_menu);
        localStorage.setItem("userDateFormat", data?.user_date);
        localStorage.setItem("filteredApproval", false);
        const menuaItems = process.env?.REACT_APP_MENU_LIST;
        const menuList = JSON.parse(menuaItems);
        const menuName = menuList.find((item) => item.id == data?.allowed_menu);
        // dispatch(listOrganisationDropDown());
        dispatch(RolePermissionDisplay({ orgid: data?.default_orgid }));
        if (data?.login_status == 0) {
            navigate("/admin-setting?type=tour");
        } else if (menuName) {
            localStorage.setItem("defaultLogin", menuName.name);
            navigate(menuName.name);
        }
    }
    if (getFolder != null && getFolder?.length > 0) {
        getFolder?.map((item) => {
            const currentDate = new Date();
            const targetDate = new Date(item?.time);
            const differenceInMilliseconds = targetDate - currentDate;
            const millisecondsInWeek = 7 * 24 * 60 * 60 * 1000;
            const isMoreThanWeek = differenceInMilliseconds > millisecondsInWeek;
            if (isMoreThanWeek) {
                removeItemFromLocalForage(item?.key);
            }
        });
    }
    return (
        <div className="mt-[-2rem]">
            {isSubmit && error && <MessageBox error={error} />}
            {isSubmit && !data?.status && <MessageBox error={data?.message} />}
            <h4 className="text-light-20 text-2xl font-interM mb-4">Welcome to MyiQ.Ai</h4>
            <form action="" onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="form-control">
                    <label htmlFor="" className="text-light-40 mb-2">
                        <span className="text-[red] mr-1">*</span>
                        Business Email
                    </label>

                    <Input
                        tabIndex="2"
                        className="border border-light-840 rounded-[8px] px-[20px] py-2 w-full"
                        onChange={(e) => handleOnChangeText(e)}
                        value={formik.values.login_email}
                        maxLength={250}
                        placeholder="email@address.com"
                        name="login_email"
                        autoFocus
                    />

                    {formik.errors.login_email && formik.touched.login_email ? (
                        <p className="text-light-50 text-xs">{formik.errors?.login_email}</p>
                    ) : null}
                </div>
                <div className="form-control relative">
                    <label htmlFor="" className="text-light-40 mb-2">
                        <span className="text-[red] mr-1">*</span>
                        Password
                    </label>
                    <div className="relative">
                        <Input
                            tabIndex="3"
                            className="border border-light-840 rounded-[8px] py-2 pl-[20px] pr-[55px] w-full"
                            name="login_password"
                            type={isRevealPwd ? "text" : "password"}
                            onChange={(e) => handleOnChangeText(e)}
                            value={formik.values.login_password}
                            placeholder="&bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull; &bull;"
                        />

                        <Link
                            onClick={() => setIsRevealPwd((prevState) => !prevState)}
                            tabIndex="9"
                            className="absolute cursor-pointer top-[57%] translate-y-[-57%] right-[26px]">
                            <img
                                title={isRevealPwd ? "Hide password" : "Show password"}
                                src={isRevealPwd ? `/images/common/eyeOpen.svg` : `/images/common/eyeClose.svg`}
                            />
                        </Link>
                    </div>
                    {formik.errors.login_password && formik.touched.login_password ? (
                        <p className="text-light-50 text-xs">{formik.errors?.login_password}</p>
                    ) : null}
                    <div className="w-full text-end mt-2">
                        <Link to="/forgotpassword" tabIndex="8" className="inline-block text-light-40">
                            <u>Forgot password?</u>
                        </Link>
                    </div>
                </div>
                <Button
                    title="Login"
                    disabled={fetching}
                    tabIndex="4"
                    type="submit"
                    className={`w-full buttonPrimary shadow-[0px_12.1167px_24.2334px_rgba(0,179,153,0.05)] rounded-[8px] text-white text-lg py-[8px]`}>
                    {fetching ? `Logging in...` : `Login`}
                </Button>

                <div className="OrLine my-[20px]">OR</div>

                <div className="flex flex-col xl:flex-row xl:space-x-4">
                    <Button
                        onClick={() => loginGoogle()}
                        tabIndex="5"
                        type="button"
                        className="w-full bg-white flex font-interR mb-4 justify-center shadow-[0px_1px_4px_rgba(0,0,0,0.2)] rounded-[8px] text-light-40 py-[14px]">
                        <img className="mr-2" src="/images/signin/google.svg" alt="google" /> Sign in with Google
                    </Button>
                    <Button
                        onClick={() => loginOutlook()}
                        type="button"
                        tabIndex="6"
                        className="w-full bg-white flex font-interR mb-4 justify-center shadow-[0px_1px_4px_rgba(0,0,0,0.2)] text-light-40 rounded-[8px] text-light-40 py-[14px]">
                        <img className="mr-2" src="/images/signin/outlook.svg" alt="google" /> Sign in with Office 365
                    </Button>
                </div>
                {/* <div className="flex space-x-4 mb-10 justify-center items-center"> */}

                {/* <MicrosoftLogin
            clientId={"11f3f317-c500-4cc9-88c1-4da591df06ce"}
            graphScopes={["User.Read"]}
            redirectUri="http://localhost:35281/Authenticate/authenticateResp"
            authCallback={responseMS} >
              </MicrosoftLogin> */}
                {/* </div> */}
                <Button
                    disabled={fetching}
                    className="w-full cursor-default bg-white flex mb-[40px] font-interR justify-center shadow-[0px_1px_4px_rgba(0,0,0,0.2)] border-[1px] rounded-[8px] text-light-40 py-[14px]">
                    Don’t have an account?{" "}
                    <Link to="/signup" tabIndex="7" className="ml-2 text-light-20 hover:opacity-[.7] duration-300">
                        {" "}
                        Sign up
                    </Link>
                </Button>
            </form>
        </div>
    );
};

export default Form;
