import {
    ROLE_FAILURE,
    ROLE_REQUEST,
    ROLE_RESET,
    ROLE_SUCCESS,
    USER_ROLE_BYORG_FAILURE,
    USER_ROLE_BYORG_REQUEST,
    USER_ROLE_BYORG_RESET,
    USER_ROLE_BYORG_SUCCESS,
    USER_ROLEBYORG_VIEW_FAILURE,
    USER_ROLEBYORG_VIEW_REQUEST,
    USER_ROLEBYORG_VIEW_RESET,
    USER_ROLEBYORG_VIEW_SUCCESS,
    LIST_ORG_ROLEBYUSER_REQUEST,
    LIST_ORG_ROLEBYUSER_SUCCESS,
    LIST_ORG_ROLEBYUSER_RESET,
    LIST_ORG_ROLEBYUSER_FAILURE,
    ADD_UPDATE_USER_ROLEBYUSER_SUCCESS,
    ADD_UPDATE_USER_ROLEBYUSER_REQUEST,
    ADD_UPDATE_USER_ROLEBYUSER_RESET,
    ADD_UPDATE_USER_ROLEBYUSER_FAILURE,
    ROLE_PERMISSION_LIST_REQUEST,
    ROLE_PERMISSION_LIST_SUCCESS,
    ROLE_PERMISSION_LIST_FAILURE,
    ROLE_PERMISSION_LIST_RESET,
    ROLE_PERMISSION_ADD_REQUEST,
    ROLE_PERMISSION_ADD_SUCCESS,
    ROLE_PERMISSION_ADD_FAILURE,
    ROLE_PERMISSION_ADD_RESET,
    ROLE_PERMISSION_VIEW_REQUEST,
    ROLE_PERMISSION_VIEW_SUCCESS,
    ROLE_PERMISSION_VIEW_FAILURE,
    ROLE_PERMISSION_VIEW_RESET,
    ROLE_PERMISSION_DISPLAY_REQUEST,
    ROLE_PERMISSION_DISPLAY_SUCCESS,
    ROLE_PERMISSION_DISPLAY_FAILURE,
    ROLE_PERMISSION_DISPLAY_RESET,
    GET_ROLE_LIST_ALLOCATION_REQUEST,
    GET_ROLE_LIST_ALLOCATION_SUCCESS,
    GET_ROLE_LIST_ALLOCATION_FAILURE,
    GET_ROLE_LIST_ALLOCATION_RESET,
} from "../types/types";

const intAuthRoleState = {
    role: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function roleidReducer(state = intAuthRoleState, action) {
    switch (action.type) {
        case ROLE_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ROLE_SUCCESS:
            return {
                role: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ROLE_RESET:
            return {
                role: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ROLE_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const UserRoleORg = {
    roleResult: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function userRolebyOrg(state = UserRoleORg, action) {
    switch (action.type) {
        case USER_ROLE_BYORG_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_ROLE_BYORG_SUCCESS:
            return {
                roleResult: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_ROLE_BYORG_RESET:
            return {
                roleResult: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_ROLE_BYORG_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const ViewUserByRoleOrgState = {
    role: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ViewuserRolebyOrg(state = ViewUserByRoleOrgState, action) {
    switch (action.type) {
        case USER_ROLEBYORG_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case USER_ROLEBYORG_VIEW_SUCCESS:
            return {
                role: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case USER_ROLEBYORG_VIEW_RESET:
            return {
                role: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case USER_ROLEBYORG_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const ListOrgRoleByUserState = {
    role: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ListOrgRoleByUser(state = ListOrgRoleByUserState, action) {
    switch (action.type) {
        case LIST_ORG_ROLEBYUSER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case LIST_ORG_ROLEBYUSER_SUCCESS:
            return {
                role: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case LIST_ORG_ROLEBYUSER_RESET:
            return {
                role: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case LIST_ORG_ROLEBYUSER_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const userRoleByUserState = {
    roleResult: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function addUpdateuserRolebyUser(state = userRoleByUserState, action) {
    switch (action.type) {
        case ADD_UPDATE_USER_ROLEBYUSER_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ADD_UPDATE_USER_ROLEBYUSER_SUCCESS:
            return {
                roleResult: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ADD_UPDATE_USER_ROLEBYUSER_RESET:
            return {
                roleResult: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ADD_UPDATE_USER_ROLEBYUSER_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const RolePermission = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function RolePermissionState(state = RolePermission, action) {
    switch (action.type) {
        case ROLE_PERMISSION_LIST_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_LIST_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ROLE_PERMISSION_LIST_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_LIST_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const AddRolePermission = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function AddRolePermissionState(state = AddRolePermission, action) {
    switch (action.type) {
        case ROLE_PERMISSION_ADD_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_ADD_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ROLE_PERMISSION_ADD_RESET:
            return {
                data: [],
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_ADD_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const ViewRolePermission = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function ViewRolePermissionState(state = ViewRolePermission, action) {
    switch (action.type) {
        case ROLE_PERMISSION_VIEW_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_VIEW_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ROLE_PERMISSION_VIEW_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_VIEW_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

const DisplayRolePermission = {
    data: {},
    fetching: false,
    requestComplete: false,
    error: "",
};

export function DisplayRolePermissionState(
    state = DisplayRolePermission,
    action
) {
    switch (action.type) {
        case ROLE_PERMISSION_DISPLAY_REQUEST:
            return {
                ...state,
                fetching: true,
                data: action?.payload,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_DISPLAY_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case ROLE_PERMISSION_DISPLAY_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case ROLE_PERMISSION_DISPLAY_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
const DisplayRoleList = {
    data: [],
    fetching: false,
    requestComplete: false,
    error: "",
};

export function RoleListAllocationDisplay(state = DisplayRoleList, action) {
    switch (action.type) {
        case GET_ROLE_LIST_ALLOCATION_REQUEST:
            return {
                ...state,
                fetching: true,
                requestComplete: false,
                error: "",
            };
        case GET_ROLE_LIST_ALLOCATION_SUCCESS:
            return {
                data: action.payload,
                fetching: false,
                requestComplete: true,
                error: "",
            };
        case GET_ROLE_LIST_ALLOCATION_RESET:
            return {
                data: {},
                fetching: false,
                requestComplete: false,
                error: "",
            };
        case GET_ROLE_LIST_ALLOCATION_FAILURE:
            return {
                fetching: false,
                requestComplete: false,
                error: action.payload,
            };
        default:
            return state;
    }
}
