import axios from "axios";
import { SEND_CHATBOT_FAILURE, SEND_CHATBOT_REQUEST, SEND_CHATBOT_SUCCESS, SEND_CHATBOT_TEXT_SUCCESS } from "../types/types";
import authHeader from "./auth-header";

export const sendMessage = (message, time) => async (dispatch) => {
	dispatch({
		type: SEND_CHATBOT_TEXT_SUCCESS,
		payload: { message, time },
	});
};

export const ChatbotRequest = (data, time) => async (dispatch) => {
	dispatch({ type: SEND_CHATBOT_REQUEST });

	axios
		.post(`https://api.dev.myiq.ai:5000/chat`, data, {
			headers: authHeader(),
		})
		.then((response) => {
			dispatch({
				type: SEND_CHATBOT_SUCCESS,
				payload: { time, response: response.data },
			});
		})
		.catch((error) => {
			dispatch({
				type: SEND_CHATBOT_FAILURE,
				payload: error,
			});
		});
};
