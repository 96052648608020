import { FormControl, MenuItem, Select, TextField } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import Card from "../../components/UI/Card";
import Main from "../../components/UI/Main";
import { Barchart } from "../../organisms/BarChart";
import Footer from "../../organisms/Footer";
import Header from "../../organisms/Header";
import Sidebar from "../../organisms/Sidebar";
import DashboardTile from "./DashboardTile";
import DashboardChart from "./DashboardChart";
import VendorList from "./VendorList";
import VendorInvoiceList from "./VendorInvoiceList";
import MyPrioritiesTask from "./PrioritiesTask";
import { UserDropDown } from "../../redux/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect, useContext } from "react";
import { decryptId, ThisWeek, ThisYear } from "../../utils/helpers";
import isDevToolsOpened from "devtools-detect";
import ReactDOM from "react-dom";
import { DashboarChartList, DashboardCategory, DashboardVandorList, DashboardVendors, DashboardTaskList } from "../../redux/actions/dashboard";
import { getCurrentUser, parseDate } from "../../redux/actions/auth";
import FreeTrail from "../../organisms/Header/TrialPage";
import { ThemeContext } from "../../Hooks/Themes/Themeprovider";
import { trialOpenAction } from "../../redux/actions/free-trail";
import { Responsive, WidthProvider } from "react-grid-layout";
import debounce from "lodash/debounce";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { FixedUpload } from "../../organisms/FixedUpload";
import { layoutRequest } from "../../redux/actions/layout";
import { ChatBot } from "../../organisms/ChatBot";
import OnTimeProcessing from "./OnTimeProcess";
import ValidTime from "./ValidTimeProcess";
import { useMediaQuery } from "@react-hook/media-query";
import NewDashboardTile from "./NewDashboardTile";
import { NewTabsTile } from "./NewTabsTile";
// import { DrilldownChart } from "./Drill";

const ResponsiveGridLayout = WidthProvider(Responsive);
const Home = () => {
    const dispatch = useDispatch();
    const currentUser = getCurrentUser();
    const date = new Date();
    const isMediumScreen = useMediaQuery("(max-width: 1366px)");
    const Orgdemoname = localStorage.getItem("Orgdemo");
    const d = date.toLocaleDateString("en-GB");
    let hours = date.getHours();
    hours = (((hours + 11) % 12) + 1).toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedTime = `${hours}:${minutes}`;
    const [trialOpen, setTrialOpen] = useState(false);
    const orgId = localStorage.getItem("orgId");
    const VariantCard = (props) => (
        <div className={`px-[20px] py-[27px] flex items-center justify-between rounded-[16px] ${props?.className}`}>{props?.children}</div>
    );
    const profileData = useSelector((state) => state?.userListDropDownState);
    const { data: dataList } = profileData;
    const Data = useSelector((state) => state?.getFreeTrialReducerState);
    const dataLists = dataList?.filter((el) => el?.isactivated !== false || el?.user_isactive !== false);
    const [age, setAge] = useState("");
    const [user, setUser] = useState(0);
    const [ValidOpen, setValidOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [DateForm, setDateForm] = useState("");
    localStorage.setItem("UserDropdownId", user);
    const currentUserDetails = getCurrentUser();
    const orgGrupId = localStorage.getItem("orgGroup");
    const [filterVal, setFilterVal] = useState(0);
    const Orgname = localStorage.getItem("Orgdemo");
    const handleUserChange = (value) => {
        setUser(value);
        dispatch(DashboarChartList(Orgdemoname == "true" ? 3 : 1, orgId, Orgname == "true" ? ThisYear : ThisWeek, value));
        dispatch(DashboardVandorList(Orgdemoname == "true" ? 3 : 1, orgId, Orgname == "true" ? ThisYear : ThisWeek, value));
        dispatch(DashboardVendors(Orgname == "true" ? 3 : 1, orgId, Orgdemoname == "true" ? ThisYear : ThisWeek, value));
        // dispatch(DashboardCategory(orgId, value, 1));
        [1, 2, 3, 4]?.map((el, i) => {
            dispatch(DashboardCategory(orgId, value, el));
        });
        dispatch(DashboardTaskList(orgId, value, 0));
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const targetDateStr = currentUser?.expiring_date;
    const targetDate = new Date(targetDateStr);
    const currentDate = new Date();
    const matchdate = targetDate < currentDate;
    useEffect(() => {
        const data = {
            org_id: +orgId,
            status: 2,
        };
        dispatch(UserDropDown(data));
        if (matchdate && currentUser?.license_type == 2 && formattedTime == currentUser?.login_time) {
            setTrialOpen(true);
        }
    }, [dispatch]);
    const { currentMode } = useContext(ThemeContext);
    const dropdownColor = currentMode === "dark" ? "#333333" : `white`;
    const dropdownText = currentMode === "dark" ? "#ECECEC" : `#000`;

    const [layout, setLayout] = useState(currentUserDetails?.dashboard_info);
    let isResizing = false;

    // Add an event listener to detect window resizing
    window.addEventListener("resize", () => {
        isResizing = true;
    });

    // Now, in your API call function, check the isResizing flag
    const handleDrag = debounce((newLayout) => {
        if (!isResizing && !isDevToolsOpened?.isOpen) {
            // If developer tools are not open and resizing is not happening
            currentUserDetails.dashboard_info = newLayout;
            localStorage.setItem("user", JSON.stringify(currentUserDetails));
            setLayout(newLayout);
            const reqObj = {
                json: newLayout,
            };
            if (!isResizing) {
                dispatch(layoutRequest(reqObj)); // API call
            }
        }
    }, 1000);

    const location = useLocation();
    const checkpath = location.pathname == "/" || location.pathname == "/signup";
    let DraggableOpen = localStorage.getItem("draggable");
    return (
        <>
            <Sidebar />
            <Header />
            <div className="lg:pl-[6.188rem] pl-[6.5rem] pt-[6rem] pr-[.8rem] pl-4 lg:pr-[15px] main">
                {!checkpath && <ChatBot />}
                <FixedUpload />
                <div className="flex justify-between items-center mb-4 px-[10px]">
                    <h4 className={`text-2xl font-interSb text-${currentMode}-copy`}>Dashboard</h4>
                    {currentUserDetails?.role_id === 2 ? (
                        <div>
                            <FormControl
                                sx={{
                                    minWidth: "300px",
                                }}>
                                <Select
                                    value={user || "0"}
                                    name="UserList"
                                    onChange={(e) => handleUserChange(e.target.value)}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    IconComponent={() => (
                                        <img
                                            className="absolute opacity-50 right-[15px] pointer-events-none"
                                            src={currentMode !== "dark" ? `/images/header/chevronDown.svg` : `/images/header/chevronDown-white.svg`}
                                        />
                                    )}
                                    MenuProps={{
                                        sx: {
                                            "& .MuiMenu-paper": {
                                                backgroundColor: dropdownColor,
                                                color: dropdownText,
                                            },
                                            "& .MuiMenuItem-root:hover": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3)",
                                            },
                                            "& .Mui-selected": {
                                                backgroundColor: "rgba(218, 249, 244, 0.3) !important",
                                            },
                                        },
                                    }}
                                    SelectDisplayProps={{
                                        style: {
                                            paddingTop: "9px",
                                            paddingBottom: "9px",
                                            color: `${currentMode !== "dark" ? `#555555` : `#ECECEC`}`,
                                            paddingRight: "30px",
                                            border: "1px solid rgba(85, 85, 85, 0.40)",
                                            borderRadius: "8px",
                                        },
                                    }}>
                                    <MenuItem key={0} name="UserList" value={0}>
                                        {"All Users"}
                                    </MenuItem>
                                    {dataLists &&
                                        dataLists?.map((listelem, i) => {
                                            return (
                                                <MenuItem key={i + 1} name="UserList" value={listelem?.id}>
                                                    {listelem?.user_name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        </div>
                    ) : (
                        ""
                    )}{" "}
                </div>

                {/* <DashboardTile userId={user} /> */}
                <NewTabsTile currentMode={currentMode} userId={user} />
                <div className="overflow-hidden mb-[3rem] grid-layout-container">
                    <ResponsiveGridLayout
                        layouts={{ lg: layout }}
                        breakpoints={{
                            lg: 1200,
                            md: 996,
                            sm: 768,
                            xs: 480,
                            xxs: 320,
                        }}
                        cols={{ lg: 12, md: 10, sm: 8, xs: 6, xxs: 4 }}
                        rowHeight={100}
                        width={1325}
                        isDraggable={!isMediumScreen}
                        // isDraggable={DraggableOpen == "true" ? false : true}
                        autoSize={true}
                        className="layout"
                        preventCollision={false}
                        onLayoutChange={handleDrag}>
                        <div key="widget1" className="mr-2 dragDiv">
                            <DashboardChart userId={user} />
                        </div>
                        <div key="widget2" className="dragDiv">
                            <VendorList userId={user} />
                        </div>
                        <div key="widget3" className="dragDiv">
                            <OnTimeProcessing
                                userId={user}
                                setSelectedValue={setSelectedValue}
                                setDateForm={setDateForm}
                                setValidOpen={setValidOpen}
                            />
                        </div>
                        <div key="widget4" className="dragDiv">
                            {/* <DrilldownChart /> */}
                            <VendorInvoiceList userId={user} />
                        </div>

                        <div key="widget5" className="mb-[4rem] overflow-y-auto dragDiv">
                            {currentUser?.license_type !== 4 ? (
                                <MyPrioritiesTask userId={user} setFilterVal={setFilterVal} filterVal={filterVal} />
                            ) : (
                                ""
                            )}
                        </div>
                    </ResponsiveGridLayout>
                </div>

                {/* <div className="xl:flex space-x-0 xl:space-x-[20px] space-y-6 xl:space-y-0 mb-[20px]">
                    <DashboardChart userId={user} />
                    <VendorList userId={user} />
                </div> */}
                <div>{/* <VendorInvoiceList userId={user} /> */}</div>
            </div>
            <Footer />
            {ReactDOM.createPortal(
                <FreeTrail isOpened={trialOpen} handleClose={() => setTrialOpen(false)} className={trialOpen && "Show"} />,
                document.getElementById("user-modal-root")
            )}
            {ReactDOM.createPortal(
                <ValidTime
                    isOpened={ValidOpen}
                    DateForm={DateForm}
                    selectedValue={selectedValue}
                    handleClose={() => setValidOpen(false)}
                    className={ValidOpen && "Show"}
                />,
                document.getElementById("user-modal-root")
            )}
        </>
    );
};

export default Home;
